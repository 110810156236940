<template>
	<div class="opacity-page">
		<div class="header-filter" v-if="loaded">
			<div class="row-space-tbf search-row">
				<div class="space-left"><icon-search v-if="$resize && $mq.above(781)"/></div>
				<div class="content">
					<div class="search-input">
						<input type="text" :placeholder="$t('roles.search-by')" v-model="search_filter" v-debounce:500="searchFilterFunct"/>
					</div>
					<div class="actions-page mobile">
						<template v-if="!reorderList">
							<button class="btn-tbf white-blue" v-if="$auth.user().rights.admins_crud" @click="reorderList = true">
								<div class="icon">
									<icon-list />
								</div>
								<span v-if="$resize && $mq.above(600)" class="text">{{ $t('roles.reorder_btn')}}</span>
							</button>
							<button class="btn-tbf blue" v-if="$auth.user().rights.admins_crud" @click="$router.push({name: 'roles-create'})">
								<div class="icon">
									<icon-plus />
								</div>
								<span v-if="$resize && $mq.above(600)" class="text">{{ $t('roles.add-role') }}</span>
							</button>
						</template>
						<template v-else>
							<button class="btn-tbf grey mr-15" :class="{mobile: $resize && $mq.below(600)}" @click="cancelNewOrderList()">
								<span class="text">{{ $t('general.cancel') }}</span>
							</button>
							<button class="btn-tbf blue" :class="{mobile: $resize && $mq.below(600)}" @click="saveNewOrderList()">
								<span class="text">{{ $t('general.update') }}</span>
							</button>
						</template>
					</div>
				</div>
				<div class="space-right"></div>
			</div>
		</div>
		<loader-header-list v-else/>

		<template v-if="loadedList">
			<div class="data-list list-goals" v-if="roles.length">
				<div class="row-space-tbf header-count-list">
					<div class="space-left"></div>
					<div class="content">
						<div class="text-list-length">
							{{ $t('general.list_length') }} <span class="number">{{ totalResults }}</span>
						</div>
					</div>
					<div class="space-right"></div>
				</div>

				<div class="row-space-tbf header-list">
					<div class="space-left"></div>
					<div class="content">
						<div class="column-filter sortable column-name" @click="sortList('name')" v-bind:class="[sortBy == 'name' ? 'sort ' + sortDirection : '']">
							<div class="text">{{ $t('roles.role_name') }}</div>
							<div class="icon-filter"><icon-arrow /></div>
						</div>
						<div v-if="$resize && $mq.above(501)" class="column-filter column-users">
							<div class="text">{{ $t('general.users') }}</div>
						</div>
						<div v-if="$resize && $mq.above(501)" class="column-filter column-responsibilities">
							<div class="text">{{ $t('roles.responsibilities') }}</div>
						</div>
						<div class="column-filter column-edit-mobile" v-if="$resize && $mq.below(500)"></div>
					</div>
					<div class="space-right"></div>
				</div>

				<draggable v-model="roles" handle=".handle" ghost-class="ghost">
					<template v-for="role in roles">
						<div class="row-space-tbf row-list-item" :key="role.id">
							<div class="space-left" v-if="!reorderList"><icon-role v-if="$resize && $mq.above(781)"/></div>
							<div class="space-left" v-else><span class="handle">&#8597;</span></div>
							<div class="content">
								<div class="border-hover" v-if="$resize && $mq.above(781)"></div>
								<router-link :to="{ name: 'roles-show', params: {id: role.id} }" class="column-simple-text column-name link">
									<div class="text">{{ role.name }}</div>
								</router-link>
								<div class="column-simple-text column-name link" @click="showRole(role.id)">
								</div>
								<div v-if="$resize && $mq.above(501)" class="column-simple-text column-users">
									<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}" v-if="role.users.length">
										<div class="users-avatars">
											<div class="image" v-for="user in role.users.slice(0, 3)">
												<img :src="user.avatar">
											</div>
											<div class="image plus-icon" v-if="role.users.length > 3">
												<icon-plus />
												<span class="numer">{{ role.users.length - 3 }}</span>
											</div>
										</div>
										<template slot="popover">
											<div class="simple-text">{{ role.users.map(el => { return el.name }).join(", ") }}</div>
										</template>
									</v-popover>
									<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}" v-else>
										<div class="users-avatars">
											<div class="image not-users">
												<icon-no-user />
											</div>
										</div>
										<template slot="popover">
											<div class="simple-text">{{ $t('roles.not_users_for_this_role_small')}}</div>
										</template>
									</v-popover>
								</div>
								<div v-if="$resize && $mq.above(1020)" class="column-simple-text column-responsibilities">
									<div class="text">{{ role.responsibilities }}</div>
								</div>
								<div class="column-edit-mobile" v-if="$resize && $mq.below(500)">
									<div class="dropdown dropdown-edit">
										<div class="button-edit" :ref="'dropdownEditRef'+role.id" :id="'dropdownEdit'+role.id" data-toggle="dropdown">
											<icon-edit-mobile />
										</div>
										<div class="dropdown-menu dropdown-menu-right" :id="'dropdownMenu'+ role.id " :aria-labelledby="'dropdownEdit'+role.id">
											<div class="dropdown-item" @click="showRole(role.id)"><div class="simple-text">{{$t('general.view')}}</div></div>
											<div class="dropdown-item" @click="editRole(role.id)"><div class="simple-text">{{$t('general.edit')}}</div></div>
											<div class="dropdown-item" @click="showModal('clone', {itemId: role.id, type: 'role', from: 'index_roles'})">
												<div class="simple-text">{{ $t('general.clone') }}</div>
											</div>
											<div class="dropdown-item" @click="showModal('delete', {type: 'role', from: 'index_roles', model: role})"><div class="simple-text">{{$t('general.delete')}}</div></div>
										</div>
									</div>
								</div>
							</div>
							<div class="space-right">
								<div v-if="$resize && $mq.above(501)" class="dropdown dropdown-edit">
									<div class="button-edit" :ref="'dropdownEditRef'+role.id" :id="'dropdownEdit'+role.id" data-toggle="dropdown">
										<icon-edit />
									</div>
									<div class="dropdown-menu dropdown-menu-right" :id="'dropdownMenu'+ role.id " :aria-labelledby="'dropdownEdit'+role.id">
										<div class="dropdown-item" @click="showRole(role.id)"><div class="simple-text">{{$t('general.view')}}</div></div>
										<div class="dropdown-item" @click="editRole(role.id)"><div class="simple-text">{{$t('general.edit')}}</div></div>
										<div class="dropdown-item" @click="showModal('clone', {itemId: role.id, type: 'role', from: 'index_roles'})">
											<div class="simple-text">{{ $t('general.clone') }}</div>
										</div>
										<div class="dropdown-item" @click="showModal('delete', {type: 'role', from: 'index_roles', model: role})"><div class="simple-text">{{$t('general.delete')}}</div></div>
									</div>
								</div>
							</div>
						</div>
					</template>
				</draggable>
			</div>
			<div class="row-space-tbf list-empty" v-else>
				<div class="space-left"></div>
				<div class="content full">
					<!-- <div class="icon-empty">
						<img src="/build/images/no-results-found.svg">
					</div> -->
					<div class="title">{{ $t('empty.title')}}</div>
					<div class="description" v-html="$t('empty.roles_text')"></div>

					<div class="add-button" v-if="$auth.user().rights.admins_crud">
						<button class="btn-tbf blue center" @click="$router.push({name: 'roles-create'})">
							<div class="icon"><icon-plus class="white" /></div>
							<div class="text">{{ $t('roles.create-new-role') }}</div>
						</button>
					</div>
				</div>
				<div class="space-right"></div>
			</div>
		</template>
		<loader-items-list class="position-absolut-list-loader" v-else/>
		
		<infinite-loading :identifier="infiniteId" @infinite="infiniteUsersHandler" ref="infiniteLoading"><div slot="spinner"></div><div slot="no-more"></div><div slot="no-results"></div></infinite-loading>
	</div>
</template>

<script>
    import IconSearch from '../../Icons/Search'
    import IconPlus from '../../Icons/Plus'
    import IconArrow from '../../Icons/Arrow'
    import IconRole from '../../Icons/Role'
    import IconEdit from '../../Icons/EditDots'
	import IconEditMobile from '../../Icons/Edit'
	import IconClose from '../../Icons/Close'
    import LoaderItemsList from '../../PagesLoaders/ItemsList'
    import LoaderHeaderList from '../../PagesLoaders/HeaderList'
	import InfiniteLoading from 'vue-infinite-loading'
	import IconNoUser from '../../Icons/NoUser'
	import draggable from 'vuedraggable'
	import IconList from '../../Icons/List'

    export default {
    	components: {
            IconSearch,
            IconPlus,
            IconArrow,
            IconRole,
            IconEdit,
			IconEditMobile,
			IconClose,
           	LoaderItemsList, 
			LoaderHeaderList,
			IconNoUser,
			InfiniteLoading,
			draggable,
			IconList
        },
		watch: {
        	search_filter: function(val) {
				var data = Object.assign({}, this.$route.query);
				data['search'] = this.search_filter;

				if(this.search_filter == ""){ delete data['search'] }

				this.$router.push({query : data });
			}
        },
		computed: {
			soft_procedures() {
				return this.$store.getters['applications/getApplication']('Proceduri');
			},
			soft_objectives() {
				return this.$store.getters['applications/getApplication']('Obiective');
			},
			soft_organigram() {
				return this.$store.getters['applications/getApplication']('Organigrama');
			}
        },
        data() {
            return {
            	loaded: false,
				loadedList: false,
            	search_filter: '',
            	roles: [],
            	sortBy: '',
            	sortDirection: 'asc',
				page: 0,
				infiniteId: 1,
				totalResults: 0,
				reorderList: false,
            }
        },
        async mounted(){
        	if(!this.$auth.user().rights.admins_instance_crud){
        		this.$router.push({name: 'forbbiden'})
        	}

			this.$refs.infiniteLoading.status = 1
			this.$refs.infiniteLoading.$emit('infinite', this.$refs.infiniteLoading.stateChanger)

        	await this.checkQueryFilters()

			setTimeout(() => {
				var title = this.$t('general.roles');
				this.$root.$emit("navbar_title", title);
			}, 0)

			this.$root.$on('refreshRolesIndex', () => {
				this.searchFilterFunct()
			})
        },
        methods: {
			infiniteUsersHandler($state){
				var paramsCall = { view_more: this.page * 20}
        		this.queryObject(paramsCall)

				if(this.sortBy){
        			paramsCall.sort_by = this.sortBy
        			paramsCall.sort_direction = this.sortDirection
        		}

        		axios.get(`${this.$auth.user().master_instance_id}/roles`, {params: paramsCall})
        		.then(({data}) => {
        			this.totalResults = data.total_results
        			if(data.data.length){
        				this.page++
        				this.roles.push(...data.data)
						setTimeout(() => { $state.loaded() }, 200)
							if(data.data.length < 20){
								$state.complete()
							}
					}else{
						$state.complete()
					}
					this.loadedList = true

        		}).catch(error => {
        			if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
        		}).finally(() => { 
					setTimeout(() => {
						this.loaded = true
						setTimeout(() => {
							$('.opacity-page').addClass('show')
						}, 0)
					}, 0)
				})
        	},
			queryObject(paramsCall){
				var dataQuery = Object.assign({}, this.$route.query);
				
				Object.keys(dataQuery).forEach(key => {
					if(key == 'search'){ paramsCall.search = dataQuery[key] }
				});
			},
			checkQueryFilters(){
				var dataQuery = Object.assign({}, this.$route.query);
				
				Object.keys(dataQuery).forEach(key => {
					if(key == 'search'){ this.search_filter = dataQuery[key] }
				});

				this.queryFiltersCount = this.search_filter != "" ? Object.keys(dataQuery).length - 1 : Object.keys(dataQuery).length
			},
			sortList(column){
				if(column === this.sortBy) {
                    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
                }
                this.sortBy = column;

				this.searchFilterFunct()
			},
			showRole(roleSlug){
				this.$router.push({ name: 'roles-show', params: {id: roleSlug} })
			},
			editRole(roleSlug){
				this.$router.push({ name: 'roles-edit', params: {id: roleSlug} })
			},
			showModal(type, data = false, itemId = false){
				this.$root.$emit('open_modal', type, data, itemId);
			},
			searchFilterFunct(){
				this.loadedList = false
				this.page = 0
				this.roles = []
				this.infiniteId += 1
			},
			saveNewOrderList(){
				let arrayToStore = this.roles.map((el, index) => {return {id: el.id, order_no: index }; })

				axios.post(`/${this.$auth.user().master_instance_id}/roles/reorder`, {reorder: arrayToStore})
				.then(() => {
					this.reorderList = false;
				})
			},
			cancelNewOrderList(){
					this.reorderList = false
					this.searchFilterFunct()
			},

        }
    }
	function getByKeywordFilter(list, keyword){
		const search = keyword.trim()

		if (!search.length) return list
		return list.filter(item => item.name.toLowerCase().indexOf(search.toLowerCase()) > -1)
	}
</script>

<style lang="scss" scoped>
	.list-goals{
		.row-list-item{
			&:hover{
				.content{
					.column-name{
							.text{
								font-weight: 700;
							}
						}
				}
			}
			.content{
				.users-avatars{
					.image{
						border: 1px solid lightgray;
						&.not-users{
							display: flex;
							align-items: center;
							justify-content: center;
							svg{
								height: 14px;
								width: auto;
							}
						}
					}
				}
			}
		}
		.column-name{
			flex: 1 1 auto;
			&.link{
				outline: none;
				cursor: pointer;
			}
		}
		.column-users{
			flex: 0 0 200px;
		}
		.column-responsibilities{
			flex: 0 0 150px;
		}
	}
	.dropdown-item {
		&.disabled{
			color: #AFB1B8 !important;
		}
	}
</style>